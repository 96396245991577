import React from "react"
import SocialLinks from "../constants/socialLinks"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <SocialLinks className="footer-links"></SocialLinks>
      </div>
      <h4>Copyright &copy; {new Date().getFullYear()} <span>Nishant Tiwari</span> All Rights Reserved</h4>
      
    </footer>
  )
  }
export default Footer
